@use "src/styles/style-variables" as var;

@mixin recrewt-form-field-style() {
  .mat-styled-input {
    border: 2px solid var.$primary !important;
    border-radius: 6px;
    padding: 6px;
  }

  .form-field-box {
    border: 1px solid rgba(black, 0.4);
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    min-width: 200px;

    &.active {
      border-color: transparent;
      transition: box-shadow 0.15s ease-in;
      box-shadow: 0 0 0 2px var.$primary;
    }

    &:hover:not(.active) {
      border-color: transparent;
      box-shadow: 0 0 0 2px black;
      transition: box-shadow 0.15s ease-in-out;
    }
  }

  .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px !important;
    background-color: white;
  }

  .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0 !important;
    background-color: white;
  }

  .mat-form-field-outline-gap {
    background-color: white;
  }

  .mat-form-field.remove-form-border {
    .mat-form-field-infix {
      border-width: 0;
      border-color: transparent;
    }

    .mat-form-field-underline {
      visibility: hidden;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .mat-form-field.no-hint {
    .mat-form-field-wrapper {
      margin-bottom: -1.25em;
    }
  }

  .mat-form-field.clear-infix {
    .mat-form-field-infix {
      border-top: none !important;
      margin-top: 4px;
    }
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: none;
      width: initial;
      opacity: 0;
    }
    &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: initial;
    }
  }

  .mat-form-field.slim {
    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0 !important;
    }

    .mat-select-arrow {
      margin-top: 8px;
    }

    &.mat-focused .mat-form-field-label-wrapper {
      top: -1.3em;
    }

    &:not(.mat-focused) input:not([value=""]) + .mat-form-field-label-wrapper {
      top: -1.3em;
    }

    &:not(.mat-focused) .mat-form-field-label-wrapper {
      top: -1.6em;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }
  }

  .mat-form-field-infix:has(recrewt-unix-timestamp-picker) {
    padding: 2px 0 1px 0;
  }
}
