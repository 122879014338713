@use 'sass:list';
@use 'sass:map';
@use "../../node_modules/@material/layout-grid/variables";
@use "../../node_modules/@material/layout-grid/mixins";

.height-match-screen {
  height: 100vh;
}

.height-match-parent {
  height: 100%;
}

.height-wrap-content {
  height: min-content;
}

.width-match-screen {
  width: 100vw;
}

.width-match-parent {
  width: 100%;
}

.width-wrap-content {
  width: min-content;
}

.no-overflow {
  overflow: hidden;
  position: relative;
}

.auto-overflow {
  overflow: auto;
}

@each $size in map.keys(variables.$columns) {
  @include mixins.media-query_($size) {
    @for $i from 6 through 9 {
      .width-percent-#{$i * 10}-#{$size} {
        width: #{$i * 10%} !important;
      }
    }
  }
}

@for $i from 6 through 9 {
  .width-percent-#{$i * 10} {
    width: #{$i * 10%};
  }
}
