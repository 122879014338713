@use "src/styles/style-variables" as var;
@use 'node_modules/@angular/material' as mat;

@mixin recrewt-expansion-panel-style() {
  mat-expansion-panel.light-bg {

    .mat-expansion-panel-body {
      box-shadow: inset 0 -1px #ebeef1;
      background-color: #F6F8FA;
    }

    .mat-expansion-panel-header {
      border-radius: 0;
      box-shadow: inset 0 -1px #ebeef1;
    }
  }
}
