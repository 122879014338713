@use "style-variables" as var;

html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
}

.mat-body-1,
.mat-display-3 {
  margin: 0;
  padding: 0;
}

*,
*:focus,
*:hover {
  outline: none;
}

div * {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.on-scrollbar {
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 16px;
    opacity: 0.87;
  }

  &::-webkit-scrollbar-thumb:hover {
    transition: background-color 1s ease-in-out;
    background: #969696;
  }
}

button.cf3Mob {
  right: 16px;
  position: fixed;
  bottom: 16px;
}

.cfGMLl .cf3Mob[data-cookiefirst-button=primary]:not([disabled]) {
  background: var.$primary !important;
  border-color: var.$primary !important;

  &:hover {
    color: var.$primary !important;
    background: white !important;

  }
}


cdk-virtual-scroll-viewport {
  div.cdk-virtual-scroll-content-wrapper {
    width: 100% !important;
  }
}
