@use "src/styles/typography" as type;
@use "src/styles/style-variables" as var;

@use '../../../node_modules/@angular/material/index' as mat;

@mixin recrewt-stepper-style() {
  .mat-step-icon {
    border-radius: 3px;

    .mat-step-icon-content {
      color: white !important;
    }
  }

  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    .mat-step-icon-content {
      color: var.$on-primary !important;
    }
  }

  .static {
    .mat-vertical-stepper-header,
    .mat-horizontal-stepper-header {
      pointer-events: none !important;
    }
  }

  .mat-step-label-selected {
    font-size: mat.font-size(type.$recrewt-typography, subheading-1);
    font-weight: mat.font-weight(type.$recrewt-typography, subheading-1);
  }

  .no-stepper-header {
    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-vertical-stepper-header,
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    .mat-vertical-content-container {
      margin-left: 0 !important;
    }

    .mat-vertical-content {
      padding: 0 !important;
    }

    .mat-stepper-vertical-line::before {
      border-left-width: 0;
    }
  }




  .full-width-dialog .mat-dialog-container {
    max-width: 100vw !important;
    mat-dialog-container.mat-dialog-container {
      padding: 0 !important;
      border-radius: 16px;
      overflow: hidden;
    }
  }

  .full-width-dialog-gray-bg .mat-dialog-container {
    max-width: 100vw !important;
    background-color: #f4f5f7;
    mat-dialog-container.mat-dialog-container {
      padding: 0 !important;
      border-radius: 16px;
      overflow: hidden;
    }
  }
}
