@use '../../node_modules/@angular/material' as mat;
@use "light-theme" as theme;

$accent: mat.get-color-from-palette(theme.$recrewt-accent);
$accent-100: mat.get-color-from-palette(theme.$recrewt-accent, 100);
$accent-200: mat.get-color-from-palette(theme.$recrewt-accent, 200);
$accent-300: mat.get-color-from-palette(theme.$recrewt-accent, 300);
$accent-500: mat.get-color-from-palette(theme.$recrewt-accent, 500);
$accent-900: mat.get-color-from-palette(theme.$recrewt-accent, 900);

$warn: mat.get-color-from-palette(theme.$recrewt-warn);
$warn-100: mat.get-color-from-palette(theme.$recrewt-warn, 100);

$on-accent: var(--theme-on-accent);
$on-primary: var(--theme-on-primary);
$on-primary-medium: var(--theme-on-primary-medium);
$on-primary-low: var(--theme-on-primary-low);

$primary: mat.get-color-from-palette(theme.$recrewt-primary);
$primary-50: mat.get-color-from-palette(theme.$recrewt-primary, 50);
$primary-100: mat.get-color-from-palette(theme.$recrewt-primary, 100);
$primary-200: mat.get-color-from-palette(theme.$recrewt-primary, 200);
$primary-300: mat.get-color-from-palette(theme.$recrewt-primary, 300);
$primary-400: mat.get-color-from-palette(theme.$recrewt-primary, 400);

$nudge-amount: 8px;

$xs: 0;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;
