@use "src/styles/style-variables" as var;

@mixin recrewt-chip-style() {
  mat-chip {
    font-size: 1em;
    padding: 3px 11px;
    border-radius: 3px;
    background-color: var.$primary;
    //color: white !important;
    height: fit-content !important;

    mat-icon {
      //color: white !important;
    }

    &.accent {
      background-color: var.$accent;
      color: var.$on-accent !important;
    }

    &.primary {
      background-color: var.$primary;
      color: var.$on-primary !important;
    }
  }

  mat-chip.placeholder {
    font-size: 1em;
    padding: 3px 11px;
    background-color: #6b778c33;
    color: black !important;
    height: fit-content !important;

    mat-icon {
      color: black !important;
    }
  }

  .outline-chip {
    border-radius: 3px !important;
    background-color: transparent !important;
    color: var.$primary !important;
    border: 1px solid var.$primary !important;
    padding: 2px 3px;
    margin-right: 4px;
  }
}
