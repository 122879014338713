@use 'src/styles/style-variables' as var;

@mixin recrewt-slide-toggle-style() {
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: var.$accent;
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: var.$accent-200;
  }

  .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: var.$primary;
  }

  .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: var.$primary-200;
  }
}
