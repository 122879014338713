@use '@angular/material' as mat;
@use 'src/styles/style-variables' as var;

@mixin sidenav-component-theme() {
  .mat-sidenav {
    &.primary {
      background-color: var.$primary !important;
    }

    .active {
      .mat-list-item-content {
        padding-left: 12px;
      }
    }
  }
}
