@use 'sass:list';
@use 'sass:map';
@use "../../node_modules/@material/layout-grid/variables";
@use "../../node_modules/@material/layout-grid/mixins";
@use "style-variables" as var;

.no-pad {
  margin: 0 !important;
  padding: 0 !important;
}

@for $i from 1 through 6 {
  .m-#{$i} {
    margin: #{$i * var.$nudge-amount} !important;
  }

  .m-l-#{$i} {
    margin-left: #{$i * var.$nudge-amount} !important;
  }

  .m-r-#{$i} {
    margin-right: #{$i * var.$nudge-amount} !important;
  }

  .m-t-#{$i} {
    margin-top: #{$i * var.$nudge-amount} !important;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i * var.$nudge-amount} !important;
  }

  .m-v-#{$i} {
    margin-top: #{$i * var.$nudge-amount} !important;
    margin-bottom: #{$i * var.$nudge-amount} !important;
  }

  .m-h-#{$i} {
    margin-left: #{$i * var.$nudge-amount} !important;
    margin-right: #{$i * var.$nudge-amount} !important;
  }

  .p-#{$i} {
    padding: #{$i * var.$nudge-amount} !important;
  }

  .p-l-#{$i} {
    padding-left: #{$i * var.$nudge-amount} !important;
  }

  .p-r-#{$i} {
    padding-right: #{$i * var.$nudge-amount} !important;
  }

  .p-t-#{$i} {
    padding-top: #{$i * var.$nudge-amount} !important;
  }

  .p-b-#{$i} {
    padding-bottom: #{$i * var.$nudge-amount} !important;
  }

  .p-v-#{$i} {
    padding-top: #{$i * var.$nudge-amount} !important;
    padding-bottom: #{$i * var.$nudge-amount} !important;
  }

  .p-h-#{$i} {
    padding-left: #{$i * var.$nudge-amount} !important;
    padding-right: #{$i * var.$nudge-amount} !important;
  }
}

@each $size in map.keys(variables.$columns) {
  @include mixins.media-query_($size) {
    @for $i from 1 through 6 {
      .m-#{$i}-#{$size} {
        margin: #{$i * var.$nudge-amount} !important;
      }

      .m-l-#{$i}-#{$size} {
        margin-left: #{$i * var.$nudge-amount} !important;
      }

      .m-r-#{$i}-#{$size} {
        margin-right: #{$i * var.$nudge-amount} !important;
      }

      .m-t-#{$i}-#{$size} {
        margin-top: #{$i * var.$nudge-amount} !important;
      }

      .m-b-#{$i}-#{$size} {
        margin-bottom: #{$i * var.$nudge-amount} !important;
      }

      .m-v-#{$i}-#{$size} {
        margin-top: #{$i * var.$nudge-amount} !important;
        margin-bottom: #{$i * var.$nudge-amount} !important;
      }

      .m-h-#{$i}-#{$size} {
        margin-left: #{$i * var.$nudge-amount} !important;
        margin-right: #{$i * var.$nudge-amount} !important;
      }

      .p-#{$i}-#{$size} {
        padding: #{$i * var.$nudge-amount} !important;
      }

      .p-l-#{$i}-#{$size} {
        padding-left: #{$i * var.$nudge-amount} !important;
      }

      .p-r-#{$i}-#{$size} {
        padding-right: #{$i * var.$nudge-amount} !important;
      }

      .p-t-#{$i}-#{$size} {
        padding-top: #{$i * var.$nudge-amount} !important;
      }

      .p-b-#{$i}-#{$size} {
        padding-bottom: #{$i * var.$nudge-amount} !important;
      }

      .p-v-#{$i}-#{$size} {
        padding-top: #{$i * var.$nudge-amount} !important;
        padding-bottom: #{$i * var.$nudge-amount} !important;
      }

      .p-h-#{$i}-#{$size} {
        padding-left: #{$i * var.$nudge-amount} !important;
        padding-right: #{$i * var.$nudge-amount} !important;
      }
    }
  }
}
