.text-weight-normal {
  font-weight: normal;
}

.text-weight-bold {
  font-weight: bold !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end;
}

.text-align-start {
  text-align: start;
}

.text-align-justify {
  text-align: justify;
  hyphens: auto;
}
