@use '../node_modules/@angular/material/index' as mat;
@use "styles/style-variables" as var;

@import "styles/style-reset";

@import "styles/light-theme";
@import "styles/typography";

@import "src/styles/custom/tab-bar";
@import "src/styles/custom/button";
@import "src/styles/custom/chip";
@import "src/styles/custom/card";
@import "src/styles/custom/stepper";
@import "src/styles/custom/form-field";
@import "src/styles/custom/expansion-panel";
@import "src/styles/custom/slide-toggle";

@import "src/styles/margin";
@import "src/styles/size";
@import "src/styles/text";
@import "src/styles/border";

@import "~@fortawesome/fontawesome-free/css/all.css";

@import "src/app/layout/sidenav/sidenav.component.scss-theme.scss";

@include mat.core($recrewt-typography);

@mixin custom-components-theme() {
  @include recrewt-tab-bar-style();
  @include recrewt-button-style();
  @include recrewt-chip-style();
  @include recrewt-card-style();
  @include recrewt-stepper-style();
  @include recrewt-form-field-style();
  @include recrewt-slide-toggle-style();
  @include sidenav-component-theme();
  @include recrewt-expansion-panel-style();
}

.recrewt-light-theme {
  @include mat.all-component-themes($recrewt-company-suite-light-theme);
  @include custom-components-theme();
}

.primary-back {
  background-color: var.$primary;
  color: var.$on-primary;
}

.primary-back-medium {
  background-color: var.$primary-300;
  color: var.$on-primary;
}

.primary {
  color: var.$primary;
}

.accent {
  color: var.$accent;
}

.accent-back {
  color: white;
  background-color: var.$accent;
}

.accent-variant-back {
  color: white;
  background-color: var.$primary;
}

.content {
  height: 100vh;
}

a {
  text-decoration: none;
  color: var.$accent;

  &:hover {
    color: var.$primary;
  }
}

.surface {
  background-color: white;
}

.on-surface-medium {
  color: #969696;
}

.gradient-icon {
  background-image: linear-gradient(var.$primary, var.$accent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clickable {
  cursor: pointer;

  &:active {
    @include mat.elevation(7);
  }

  transition: box-shadow 0.15s ease-in-out;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.on-primary-medium {
  color: var.$on-primary;
  opacity: 0.74;
}

.on-primary {
  color: white;
}

.on-primary-low {
  color: var.$on-primary;
  opacity: 0.38;
}

.primary-light {
  background-color: var.$primary-100;
}

.primary-medium {
  color: var.$primary-300;
}

.decoration {
  top: 0;
  position: absolute;
  width: 60vw;
  min-width: 500px;
}

.position-top-left {
  transform: translateX(-40%) translateY(-68%);
}

.position-top-right {
  transform: translateX(40%) translateY(-68%);
}

.tint-white {
  filter: brightness(0) invert(1);
}

.warn {
  color: var.$warn;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.54);
}

.hover-effect {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    transition: background-color 0.25s ease-in-out !important;

    &.gray {
      background-color: rgb(243, 243, 243) !important;
    }
  }
}

p.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

snack-bar-container.success {
  background: green !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

snack-bar-container.error-msg {
  background: var.$warn !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

snack-bar-container.info {
  background: var.$primary !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

* {
  transition: background-color 0.2s ease-in-out;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.scroll-y {
  overflow-y: auto;
}

.recrewt-card {
  border: 1px solid #dee2e6;
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.err-box {
  @extend .b-rad-1;
  @extend .p-1;
  border: var.$warn solid 2px;
  background-color: var.$warn-100;
}

.info-box {
  border-left: var.$primary solid 2px;
  padding-left: 16px;
}

.light-bg {
  background-color: #F6F8FA;
}

.light-bottom-border {
  box-shadow: inset 0 -1px #ebeef1;
}

.blob {
  position: absolute;
  left: calc(50% - 46px);
  top: calc(50% - 46px);
  border-radius: 50%;
  width: 94px;
  height: 94px;
  box-shadow: 0 0 0 0 #ff8f00;
  animation: pulseBlob 2s infinite;
}

.loader-pulse {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  z-index: 10;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.92);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.92);
  }
}

@keyframes pulseBlob {
  0% {
    transform: scale(0.92);
    box-shadow: 0 0 0 0 #ff8f00aa;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 16px #ff8f0000;
  }

  100% {
    transform: scale(0.92);
    box-shadow: 0 0 0 0 #ff8f0000;
  }
}


