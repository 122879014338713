.b-rad-1 {
  border-radius: 3px;
}

.circle {
  border-radius: 50%;
}

.dashed-1 {
  border: dashed 1px #969696;
}
